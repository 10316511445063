.caseSection {
  padding: 20px 0 50px;
}

.caseBanner {
  background-color: #F5F5F5;
  min-height: 330px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
}

.caseBanner h1 {
  font-size: 36px;
  line-height: 1.25;
  text-align: center;
  color: #121212;
  max-width: 700px;
  margin: auto;
}

.armsAndResults {
  padding: 55px 0 90px;
  display: flex;

}

.armsAndResults div {
  width: 50%;
  padding-right: 20px;
}

.armsAndResults p {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.36;
  color: #121212;
  margin-bottom: 35px;
}

.armsAndResults ul {
  max-width: 495px;
}

.armsAndResults ul li {
  padding-left: 20px;
  position: relative;
  padding-bottom: 20px;
}

.armsAndResults ul>li:before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 5px;
}

.armsList>li:before {
  background-color: #FED230;

}

.resultsList>li:before {
  background-color: #115AD2;

}

.breadcrumbs a {
  font-size: 10px;
  line-height: 2;
  padding: 7px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  color: #5F5F5F;
  transition: 0.5s;
}
.breadcrumbs a:hover {
  color: #115AD2;
}
.breadcrumbs a:not(:last-child):hover:after {
  border-left: 3px solid #115AD2;
  transform: translate(5px, -50%);
} 
.breadcrumbs a:not(:last-child):after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: 3px;
  height: 4px;
  border: 3px solid transparent; 
  border-left: 3px solid #5F5F5F;
  transform: translate(0, -50%);
  transition: 1s;
} 
.tagsList p {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.36;
  color: #121212;
}
@media (max-width: 768px) {
  .caseBanner {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }

  .breadcrumbs {
    display: none;
  }

  .armsAndResults {
    flex-direction: column;
  }

  .armsAndResults div {
    width: 100%;
    padding-right: 0;
  }

  .armsList {
    margin-bottom: 40px;
  }

  .armsAndResults p {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .caseBanner h1 {
    font-size: 24px;
  }

  .caseBanner {
    min-height: 270px;
    margin-bottom: 30px;
  }

  .armsAndResults {
    padding: 30px 0 60px;
  }

  .armsAndResults p {
    font-size: 18px;
  }

  .tagsList {
    display: none;
  }
}