.casesPage {
  padding-top: 100px;
}
.casesPage h2:first-child {
  /* text-align: center; */
  margin-bottom: 110px;
}

@media (max-width: 500px) {
  .casesPage {
    padding-top: 25px;
  }
  .casesPage h2:first-child {
    margin-bottom: 50px;
  }
}