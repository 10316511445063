.downloadWrap {
  display: flex;
}

.downloadWrap a {
  text-transform: uppercase;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.39;
  color: #115AD2;
  margin-right: 6px;
}


.iconWrap {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

}

.iconWrap:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 20px;
  height: 2px;
  background-color: #115AD2;
}

.iconWrap img {
  transition: 0.2s  ease-in-out;
  transform: translateY(-10px);
}
.downloadWrap a:hover {
  color: #4A8CFA;
}
.downloadWrap a:hover ~.iconWrap img {
  transform: translateY(-1px);
}

@media (max-width: 500px) {
  .downloadWrap{
    order: 1;
  }
  .downloadWrap a {
    font-size: 14px;
  }
  .iconWrap:after {
    bottom: 2px;
  }
}