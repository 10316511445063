.bitrixBlock {
  padding: 115px 0 85px;
}

.bitrixBlock h2 {
  margin-bottom: 50px;
}



/* .bitrixCase {
  
  margin: 0 auto;
  max-width: 360px;
  min-height: 360px;
  height: inherit;
  flex: 1;
  padding: 33px;
  padding-bottom: 44px;
  background-color: #115AD2;
  color: #FFFFFF;
  transition: 0.3s;
} */

/* .bitrixCase:hover {
  background-color: #4A8CFA;
} */

.bitrixImg {
  width: 96px;
  height: 96px;
  margin-bottom: 90px;
}

.bitrixTitle {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  margin-bottom: 16px;
  color: #FFFFFF;
}
.bitrixText {
  color: #FFFFFF;
}

@media (max-width: 600px) {
  .bitrixImg {
    margin: auto;
    margin-bottom: 28px;
    display: block;
  }
  .bitrixTitle {
    text-align: center;
    margin-bottom: 38px;
  }
  .bitrixBlock {
    padding: 50px 0 120px;
  }
  
}