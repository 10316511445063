.mainTitle {
  position: relative;
  z-index: 1;
  max-width: 700px;
  min-height: 252px;
}

@media (max-width: 500px) {
  .mainTitle{
    min-height: 105px;
  }
}