.advantageList {
  padding: 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.advantageItem {
  max-width: 300px;
  width: 33%;
}
.advantageItem img {
  margin-bottom: 25px;
}
.advantageItem h3 {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .advantageList {
    flex-direction: column;
  }
  .advantageItem {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .advantageItem p {
    display: none;
  }
  .advantageItem img {
    margin-bottom: 0px;
    margin-right: 13px;
  }
  .advantageItem h3 {
    margin-bottom: 0px;
  }
}
@media (max-width: 500px) {
  .advantageItem img {
    max-width: 30px;
  }
}