.сontactsBlock {
  padding: 85px 0 0;
}

.сontactsBlock h2 {
  margin-bottom: 45px;
}

.сontactsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 130px;
}

.сontactsInfo {
  width: 50%;
}

.сontactsInfo {
  padding-right: 50px;
}

.сontactsAddress {
  margin-bottom: 100px;
}

address {
  font-style: normal;
  font-family: 'Montserrat Medium', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 5px;
}

address a {
  color: #000000;
}

.сontactsBigLink {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 1.2;
  color: #115AD2;
  transition: 0.3s;
  display: inline;
}
.bottomLine {
  position: relative;
}
.bottomLine:after {
  content: '';
  display: inline;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #115AD2;
}
.telegramLink:before {
  content: '';
  position: absolute;
  bottom: 5px;
  left: calc(100% + 5px);
  /* transform: translate(0, -50%); */
  background-image: url('../../imgs/tel.svg');
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}
.сontactsBigLink + span {
  margin-top: 5px;
  display: block;
  margin-bottom: 30px;
  color: #828282;

}
.сontactsBigLink:hover {
  color: #4A8CFA;
}
.сontactsBigLink:hover:after {
  background-color: #4A8CFA;
}

.сontactsPhone {
  display: block;
  font-family: 'Montserrat Medium', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.4;
  color: #000000;
  margin-bottom: 15px;
  position: relative;
  padding-right: 35px;
}

.сontactsPhone span {
  color: #115AD2;
  font-size: 14px;
  position: absolute;
  top: -5px;
}

@media (max-width: 850px) {
  .сontactsWrap {
    flex-direction: column;
  }
  .сontactsAddress {
    margin-bottom: 60px;
  }
  .сontactsInfo {
    padding-right: 0;
    padding-bottom: 50px;
    width: 100%;
  }

}

@media (max-width: 500px) {
  .сontactsBlock {
    padding: 55px 0 0;
  }
  .сontactsBlock h2 {
    text-align: center;
  }

  address {
    font-size: 14px;
    line-height: 1.4;
  }

  .сontactsBigLink {
    font-size: 28px;
    line-height: 1.47;
    margin-bottom: 42px;
  }
  .сontactsPhones {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .сontactsWrap {
    padding-bottom: 65px;
  } 
  .сontactsPhone {
    padding-right: 0;
  }
  .сontactsPhone span{
    top: -15px;
    left: 0;
  }
  .сontactsBigLink + span {
    max-width: 200px;
  }
}