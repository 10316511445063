.aboutWrap {
  padding: 100px 0;
}

.aboutWrap h2 {
  /* text-align: center; */
  margin-bottom: 100px;
}

.aboutInner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 150px;
}

.aboutBoard {
  width: 50%;
  padding: 40px;
}

.aboutContent {
  width: 50%;
  padding: 40px;
}

.aboutContent {
  padding-bottom: 0;
}

.aboutBoard {
  max-width: 480px;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
}

.aboutBoardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aboutBoard>img {
  margin: 0 auto;
  margin-bottom: 15px;
}

.aboutName {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.21;
  color: #000000;
  margin-bottom: 15px;
}

.aboutRoles {
  display: flex;
  flex-direction: column;
  line-height: 1.78;
  flex: 1;
  margin-bottom: 50px;
}

.aboutAccent {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.36;
  color: #000000;
  margin-bottom: 40px;
}
.aboutAccentMob {
  display: none;
}

.aboutStages {
  color: #000000;
  display: flex;
  flex-direction: column;
}

.aboutStage {
  display: flex;
  border-top: 1px solid #000;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
}

.aboutStage:not(:last-child) {
  margin-bottom: 40px;
}

.aboutStage:before {
  content: '';
  position: absolute;
  top: 0;
  left: -200px;
  width: 50px;
  height: 100%;
  background-color: rgb(233, 233, 233);
  filter: blur(40px);
  transform: skewX(-30deg);
  z-index: 1;
}

.aboutStage:hover:before {
  left: calc(100% + 100px);
  transition: 1s;
  z-index: 2;
}

.aboutStageYear {
  width: 25%;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.36;
}

.aboutStageText {
  width: 75%;
  padding-left: 20px;
  line-height: 1.79;
}

.aboutStageText p:not(:last-child) {
  margin-bottom: 20px;
  padding-right: 10px;
}

.aboutContentCommon {
  margin-bottom: 120px;
  color: #000000;
  line-height: 1.79;
}

.aboutContentCommon p:not(:last-child) {
  margin-bottom: 30px;
}

.aboutSocialText {
  max-width: 210px;
  margin-top: 6px;
}

@media (max-width: 900px) {
  .aboutBoard {
    width: 40%;
    padding: 40px;
  }

  .aboutContent {
    width: 60%;
    padding: 40px;
  }

  .aboutContentCommon {
    margin-bottom: 60px;
  }

  .aboutStageYear {
    width: 18%;
  }

  .aboutStageText {
    width: 82%;
  }
}

@media (max-width: 768px) {
  .aboutInner {
    flex-direction: column;
  }

  .aboutBoard {
    flex-direction: row;
    max-width: none;
    width: 100%;
  }

  .aboutContent {
    width: 100%;
  }

  .aboutBoardContent {

    height: auto;
    width: 50%;
    padding-left: 50px;

  }

  .aboutInner {
    margin-bottom: 0;
  }

  .aboutBoard>img {
    width: 50%;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .aboutAccent {
   display: none;
  }
  .aboutAccentMob {
    display: block;
    margin-bottom: 30px;
  }


  .aboutBoard {
    padding: 35px 20px 20px;
    flex-direction: column;
    position: relative;
    left: -20px;
    right: 85px;
    width: calc(100% + 40px);
  }

  .aboutBoardContent {
    padding-left: 0px;
    width: 100%;
  }

  .aboutBoard>img {
    width: 100%;
    margin: 0 auto 20px;
  }
  .aboutWrap {
    padding: 25px 0 30px;
  }
  .aboutWrap h2 {
    margin-bottom: 50px;
  }
  .aboutContent {
    padding: 40px 0 0;
  }
  .aboutAccent {
    font-size: 18px;
  }
  .aboutAccent:last-child {
    display: none;
  }
  .aboutStage {
    flex-direction: column;
    padding: 15px 0;
  }
  .aboutStageText {
    padding-left: 0;
    width: 100%;
  }
  .aboutStageYear {
    margin-bottom: 26px;
  }
  .aboutStage:not(:last-child) {
    margin-bottom: 15px;
  }
  .aboutSocialText {
    display: none;
  }
}