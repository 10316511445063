.menu {
  width: 100%;
}

.menuList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menuItem {
  margin-left: 7%;
}

.menuLink {
  color: #121212;
  transition: 0.3s;
}
.menuLink:hover {
  color: #115AD2;
}

.active {
  color: #666666;
}

.btnBurger {
  width: 16px;
  height: 12px;
  position: relative;
  z-index: 33;
  display: none;
}

.burger,
.burger:before,
.burger:after {
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
  margin-top: -1px;
  cursor: pointer;
  transition: 0.5s;
}

.burger:before {
  content: '';
  transform: translateY(-4px);
}

.burger:after {
  content: '';
  transform: translateY(4px);
}

.burgerActive {
  height: 0;
}

.burgerActive:before {
  transform: rotate(45deg);
}

.burgerActive:after {
  transform: rotate(-45deg);
}

.menu .mobileNavigation {
  display: none;
  align-items: center;
  justify-content: flex-end;
}



.mobileNavigationActive {
  position: absolute;
  z-index: 33;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #121212;
}

.mobileNavigationActive .menuLink {
  color: #F5F5F5;
}

.mobileNavigationActive .menuItem {
  text-transform: uppercase;
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.4;
}

.mobileNavigationActive + .btnBurger > .burger,
.mobileNavigationActive + .btnBurger > .burger:before,
.mobileNavigationActive + .btnBurger > .burger:after {
  background-color: #ffffff;
}

.mobileNavigationActive .menuList {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 26px;
}
.logoBurger {
  position: absolute;
  top: 22px;
  left: 20px;
}
.сontactsPhones {
  width: calc(100% - 40px);
  position: absolute;
  bottom: 0;
  left: 20px;
}
@media (max-width: 850px) {
  .menu {
    width: auto;
  }

  .menu .navigation {
    display: none;
  }

  .menu .mobileNavigation {
    display: flex;
  }
.btnBurger {
  display: block;
}
}