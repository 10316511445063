.headerWrap {
  background-color: #ffffff;
  position: static;
  width: 100%;
  z-index: 5;

}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0 38px;
  border-bottom: 1px solid #F2F2F2;
}

.headerFix {
  position: fixed;
  transition: 0.2s;
  opacity: 0;
  animation: vis 0.2s forwards ease-in;
}
@keyframes vis {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform: translateY(0%);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  header {
    padding: 22px 0;
    border-bottom: none;

  }
}