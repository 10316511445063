.container {
  width: 100%;
  height: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}
.clamor {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 2;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 124px;
}
.clamor:before {
  content: '';
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
  transform: scale(0);
}
.centerClamor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform .2s ease-out;
  transform-origin: center;
}
.clamor:hover:before {
  background-color: #FED230;
  transform: scale(1.2);
}
.clamor:hover .centerClamor {
  transform: rotate(30deg) scale(0);
}