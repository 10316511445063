.stackBlock {
  padding: 60px 0 120px;
}
.stackBlock h2 {
  margin-bottom: 50px;
}
.stackList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stackItem {
  width: 32%;
  max-width: 260px;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.stackItem:not(:last-child) {
  margin-right: 10px;
}
.stackItem:nth-child(4n+1) .stackItem__header {
  background-color: #545CB6;
  color: #F0F0F0;
}
.stackItem:nth-child(4n+2) .stackItem__header {
  background-color: #4A8CFA;
  color: #FFFFFF;
}
.stackItem:nth-child(4n+3) .stackItem__header {
  background-color: #FED230;
  color: #000000;
}
.stackItem:nth-child(4n+4) .stackItem__header{
  background-color: #1973BA;
  color: #FFFFFF;
}


/* .stackItem:nth-child(4n+5) .stackItem__header {
  background-color: #115AD2;
  color: #FFFFFF;
} */

.stackItem__header {
  padding: 15px;
  padding-top: 5px;
  min-height: 170px;
  font-size: 60px;
  line-height: 1.25;
}

.stackItem:nth-child(4n+1) .stackItem__tag {
  background-color: #545CB6;
  color: #FFFFFF;
}
.stackItem:nth-child(4n+2) .stackItem__tag {
  background-color: #4A8CFA;
  color: #FFFFFF;
}
.stackItem:nth-child(4n+3) .stackItem__tag {
  background-color: #FED230;
  color: #121212;
}
.stackItem:nth-child(4n+4) .stackItem__tag {
  background-color: #1973BA;
  color: #FFFFFF;
}

/* .stackItem:nth-child(4n+5) .stackItem__tag {
  background-color: #115AD2;
  color: #FFFFFF;
} */

.stackItem__body {
  padding: 15px;
  background-color: #F5F5F5;
  flex: 1;
}


/* tags */
.stackItem__tag {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 5px;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
}
/* tags */

@media (max-width: 850px) {
  .stackItem {
    width: 48%;
    max-width: none;
  }
  .stackItem:not(:last-child) {
    margin-right: 0;
  }

  .stackItem__header{
    font-size: 50px;
    min-height: 130px;
  }
}

@media (max-width: 500px) {
  .stackBlock {
    padding: 30px 0 60px;
  }
  .stackList{
    flex-direction: column;
  }
  .stackItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .stackItem__header{
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    padding: 10px 15px;
  }

}