.сontactsForm {
  width: 50%;
}

.сontactsForm h4 {
  font-family: 'Montserrat Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.36;
  color: #000000;
  margin-bottom: 35px;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 460px;
}

input[type=text],
textarea {
  border: none;
  border-bottom: 1px solid #626262;
  padding: 10px 0;
  margin-bottom: 25px;
  resize: vertical;
  width: 100%;
  /* position: relative; */
  /* z-index: 2; */
}

input[type=text]:focus,
textarea:focus {
  outline: none;
}

input[type=text]::placeholder,
textarea::placeholder  {
  font-size: 14px;
  transition: 0.3s;
}
input[type=text]:focus::placeholder,
textarea:focus::placeholder {
  font-size: 0;
  
}
input[type=text]:focus + .сontactsPlaceholder,
textarea:focus + .сontactsPlaceholder {
  top: -15px;
  transform: scale(1);
  transition-delay: 0.2s;
  
}
.сontactsPlaceholderWrap {
  position: relative;
}
.сontactsPlaceholder {
  position: absolute;
  top: 0px;
  left: 0;
  transition: 0.3s;
  z-index: 1;
  transform: scale(0);
  transform-origin: center;
}


textarea {
  min-height: 80px;
}

.сontactsFormError {
  position: relative;
  top: -25px;
  color: red;
}

.сontactsFormButton {
  border: none;
  max-width: 260px;
  height: 50px;
  background: #FFC938;
  font-family: 'Montserrat Medium', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.03em;
  margin-bottom: 25px;
  cursor: pointer;
}

.сontactsForm label {
  cursor: pointer;
  padding-left: 20px;
  margin-bottom: 45px;
  text-decoration-line: underline;
  padding-right: 26px;
  display: flex;
  position: relative;
}

.сontactsForm label:before {
  content: '';
  background-image: url('../../imgs/paperclip.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 20px;
  top: 50%;
  left: 0px;
  transition: 0.1s ease-in-out;
  transform: translate(0, -50%);
}
.сontactsForm label:hover:before {
  transform: translate(0, -50%) scale(1.1);
}

input[type=file]{
  opacity: 0;
  position: absolute;
  max-width: 0;
  z-index: -1;
}
.сontactsAgreementPolity {
  display: inline-block;
  max-width: 475px;
}
.сontactsAgreementPolity a{
  text-decoration-line: underline;
  color: #626262;
}
.сontactsAgreementPolity a:hover {
  text-decoration-line: underline;
  color: #363636;
}

@media (max-width: 850px) {
  .сontactsForm {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .сontactsFormButton {
    margin: 0 auto 35px;
    min-width: 260px;
  }

  .сontactsForm h4 {
    font-size: 16px;
    line-height: 1.56;
    margin-bottom: 30px;
  }
  .сontactsAgreementPolity {
    font-size: 12px;
    line-height: 1.67;
  }
}