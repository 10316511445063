.casesBlock {
  background-color: #E5E5E5;
  padding: 55px 0 150px;
  position: relative;
  z-index: 1;
}
.casesBlock:after, .casesBlock:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 76px;
  background-image: url('../../imgs/accent-cases.png');
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 0;
}
.casesBlock:after {
  top: 56%;
  left: 0;
}
.casesBlock:before {
  bottom: 120px;
  right: 18%;
}
.casesBlock h2 {
  margin-bottom: 100px;
}

.caseItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
  position: relative;
  z-index: 1;
}

.caseItem__content {
  max-width: 365px;
  padding-right: 20px;
}

.caseItem h3 {
  margin-bottom: 33px;
}

.caseItem p {
  margin-bottom: 60px;
}

.caseItem__tags {
  margin-bottom: 55px;
}
/* .caseItem__tags > span[data-category='php'] {
  background-color: #545CB6;
  color: #F0F0F0;
  
}
.caseItem__tags > span[data-category='js'] {
  background-color: #4A8CFA;
  color: #FFFFFF;
}

.caseItem__tags > span[data-category='css'] {
  background-color: #FED230;
  color: #000000;
}

.caseItem__tags > span[data-category='python'] {
  background-color: #1973BA;
  color: #FFFFFF;
} */

.caseItem__wrapImg1 {
  position: relative;
  z-index: 1;
  left: -15px;
  line-height: 0;
}

.caseItem__wrapImg1:before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
  border: 7px solid #FED230;
  z-index: -1;
  line-height: 0;
}

.caseItem__imgAnim {
  position: relative;
  z-index: 1;
  left: -15px;
  line-height: 0;
  /* width: 50%; */
  /* height: 300px; */
}

.caseItem__imgAnim:before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  width: 0;
  height: 0;
  border: 7px solid #FED230;
  z-index: -1;
  line-height: 0;
  transition: 1s ease-in-out;
  transition-delay: 0.2s;
}

.caseItem__wrapImg img {
  width: 100%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
}

.caseItem__more {
  display: block;
}

.caseItem__more a {
  color: #115AD2;
  padding-right: 5px;
}


.caseItem__more:after {
  content: '';
  display: inline-flex;
  width: 24px;
  height: 8px;
  background-image: url(../../imgs/arrow-right.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  transition: 0.2s;
}

.caseItem__more:hover:after {
  transform: translateX(10px);
}

.moreCases {
  display: block;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.36;
  text-decoration: underline;
  color: #115AD2;
}

.caseItem__wrapParallax {
  width: 100%;
}

.caseItem__wrapImgMob {
  display: none;
}

@media (max-width: 768px) {
  .caseItem__wrapImgDesk {
    display: none;
  }

  .caseItem__wrapImgMob {
    display: block;
    margin-bottom: 20px;
  }

  .caseItem__wrapImgMob img {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
  }

  .caseItem__content {
    max-width: none;
    padding-right: 0;
  }

  .casesBlock h2 {
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .casesBlock {
    background-color: #E5E5E5;
    padding: 26px 0 50px;
  }

  .casesBlock h2 {
    margin-bottom: 50px;
  }
  .caseItem {
    margin-bottom: 110px;
  }
  .caseItem:last-child {
    margin-bottom: 60px;
  }

  .caseItem h3 {
    margin-bottom: 24px;
  }
  .caseItem p {
    margin-bottom: 25px;
  }
  .caseItem__tags {
    text-align: center;
    margin: 0 auto 25px;
  }
  .caseItem__more {
    text-align: center;
    margin: 0 auto;
  }
  .moreCases {
    text-align: center;
  }
}