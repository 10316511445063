.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
}
.slick-slide {
  display: flex;
}
.bitrixList .slick-slide  div {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 360px;
  width: 95%;
  padding: 33px;
  padding-bottom: 44px;
  background-color: #115AD2;
  color: #FFFFFF;
  transition: 0.3s;
}
.bitrixList {
  position: relative;
  z-index: 1;
}
.bitrixList .slick-slide  div:hover {
  background-color: #4A8CFA;
}

.bitrixList .slick-arrow {
  border: none;
  font-size: 0;
  width: 12px;
  height: 24px;
  background-color: transparent;
  background-image: url('../../imgs/arrow-slick.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
}

.bitrixList .slick-prev {
  left: -60px;
}

.bitrixList .slick-next {
  right: -60px;
  transform: translate(0, -50%) rotate(180deg);
}


.sliderCase {
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
  margin-bottom: 95px;

}
.sliderCase .slick-slide {
  justify-content: center;
}
.sliderCase .slick-slide div {
  width: 90%;
}
.sliderCase {
  position: relative;
  z-index: 1;
}
.sliderCase .slick-arrow {
  border: none;
  font-size: 0;
  max-width: 5%;
  width: 28px;
  height: 28px;
  background-color: transparent;
  background-image: url('../../imgs/arrow-case.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
}
.sliderCase .slick-prev {
  left: 0px;
}

.sliderCase .slick-next {
  right: 0px;
  transform: translate(0, -50%) rotate(180deg);
}


@media (max-width: 1400px) {
  .bitrixList .slick-arrow {
    top: calc(100% + 30px);
    margin: 15px;
  }
  .bitrixList .slick-prev {
    left: calc(100% - 150px);
  }
  .bitrixList .slick-next {
    right: 50px;
  }
}

@media (max-width: 600px) {
  .bitrixList .slick-slide  div {
    width: 100%;
    max-width: none;
    padding: 30px 15px;
  }
  .bitrixList .slick-prev {
    left: calc(100% - 90px);
  }
  .bitrixList .slick-next {
    right: 0;
  }

  .sliderCase {
    margin-bottom: 0;
  }
  .sliderCase .slick-slide div {
    width: 95%;
  }
  .sliderCase .slick-arrow {
    width: 20px;
    height: 20px;
    max-width: none;
  }
  .sliderCase .slick-prev {
    left: -15px;
  }
  .sliderCase .slick-next {
    right: -15px;
  }
  
}

