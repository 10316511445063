
.errorWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  text-align: center;
}

.errorWrap span {
  font-size: 150px;
  line-height: 1.21;
  color: #115AD2;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
}
.errorWrap p {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 1.25;
  color: #121212;
}
.goHome {
  color: #FED230;
  text-decoration: underline;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  transition: 0.2s;
  display: inline-block;
}
.goHome:hover {
  transform: scale(1.2) translateY(-10px);
}
.errorWrap img {
  width: 300px;
}

@media (max-width: 500px) {
  .errorWrap {
    padding: 20px 20px 60px;
  }
  .errorWrap span {
    font-size: 100px;
  }
  .errorWrap p {
    font-size: 25px;
  }
}