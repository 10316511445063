.socialGroup {
  display: flex;
}
.socialGroup li:not(:last-child) {
  margin-right: 10px;
}
.socialIcon  {
  border-radius: 50%;
  transition: 0.3s;
}

.socialGroup .socialIcon:hover {
  box-shadow: 0 0 20px 7px  #4364aa;
}