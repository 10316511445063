.bannerWrap {
  position: relative;
  overflow: hidden;
}

.mainBlock {
  padding-top: 150px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.circleText {
  position: absolute;
  width: 50%;
  max-width: 340px;
  top: 120px;
  right: 0%;
  z-index: 0;
  animation: rotate 25s linear 0s infinite;
}

.mainContent {
  width: 100%;
}


@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}

@media (max-width: 768px) {

  .mainBlock {
    padding-top: 55px;
    padding-bottom: 50px;
    position: static;
  }
.circleText {
  max-width: 270px; 
}
}

@media (max-width: 500px) {
  .mainBlock {
    overflow: hidden;
  }

  .circleText {
    width: calc(70% + 35px);
    top: 200px;
    right: -35%;
  }
}