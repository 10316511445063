footer,
footer a {
  background-color: #121212;
  color: #FFFFFF;
}

.footerWrap {
  padding: 46px 0 30px;
}

.footerRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer .logo {
  margin-bottom: 58px;
}

footer .socialGroup {
  margin-bottom: 40px;
}

.footerCol {
  width: 50%;
}

.footerNavBlock {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.footerNavBlock div {
  margin-right: 15px;
  width: 33%;
}

.footerNavBlock h5 {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 8px;
}

.footerNavItem:not(:last-child) {
  margin-bottom: 5px;
}

.footerNavBlock a {
  overflow: hidden;
  position: relative;
  display: inline-block;
  outline: none;
  color: #FFFFFF;
}

.footerNavBlock a span {
  position: relative;
  display: inline-block;
  transition: transform 0.3s;
}

.footerNavBlock a:hover span {
  transform: translateY(-100%);
}

.footerNavBlock a span:before {
  position: absolute;
  top: 100%;
  content: attr(data-hover);
  transform: translate3d(0, 0, 0);
}

.footerNavBlock .orderDev {
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #115AD2;
}

@media (max-width: 768px) {
  .footerRow {
    flex-direction: column;
    margin-bottom: 77px;
  }

  .footerCol {
    width: 100%;
  }

  .footerCol:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 65px;
  }

  footer .logo,
  footer .socialGroup {
    margin-bottom: 0;
  }
}


@media (max-width: 500px) {
  .footerPolity {
    text-align: center;
    width: 100%;
    display: block;
  }
  .footerNavBlock .orderDev {
    width: 100%;
    flex: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .footerNavBlock div {
    width: 43%;
  }
  .footerNavBlock {
    justify-content: space-between;
  }
  /* .footerNavBlock div:last-child {
   
    width: 100%;
    text-align: center;
  } */
  .footerWrap {
    padding: 42px 0 37px;
  }
  .footerNavBlock h5 {
    margin-bottom: 18px;
  }
}