.polityBlock {
  padding: 70px 0;
}

.polityBlock h1 {
  font-size: 36px;
  line-height: 1.25;
  max-width: 900px;
}

.polityBlock li h2 {
  display: inline;
  font-size: 22px;
  line-height: 1.36;
  margin-bottom: 20px;
}

.polityBlock li {
  font-family: 'Montserrat Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  color: #5F5F5F;
}

.polityBlock h2+ol {
  margin-bottom: 40px;
  /* padding-left: 20px; */
}

.polityBlock h2+ol>li:before {
  font-family: 'Montserrat Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  color: #5F5F5F;
}

.polityBlock ul li {
  padding-left: 30px;
  position: relative;

}

.polityBlock ul li:before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #115AD2;
  position: absolute;
  left: 15px;
  top: 5px;
}

.polityBlock ol {
  list-style: none;
  counter-reset: li;
}

.polityBlock ol>li:before {
  counter-increment: li;
  content: counters(li, ".") ". ";
  font-size: 22px;
  line-height: 1.36;
  font-family: 'Montserrat Bold', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #000000;
}

@media (max-width: 500px) {
  .polityBlock h1 {
    font-size: 22px;
  }

  .polityBlock li h2 {
    font-size: 18px;
  }
}