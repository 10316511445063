.logoWrap {
  display: flex;
  width: 88px;
  height: 25px;
  /* position: relative; */
}

.logoDot {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  margin-top: auto;
  width: 5px;
  height: 5px;
  /* transform: scale(3000); */
  /* width: 5px;
  height: 5px; */
  background-color: #115AD2;
  z-index: 5;
  animation: dotMove ease-out 1s forwards, dotBlink infinite 1.5s 2s;
}
@keyframes dotBlink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes dotMove {
  0% {
    /* width: 100%;
    height: 100%; */
    transform: scale(3000);
    /* top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute; */
  }
  100% {
    margin-left: 5px;
    transform: scale(1);
    /* transform: translate(40%, 40%);
    position: relative;
    height: 5px;
    transition: 2s; */
  }
  100% {
    /* position: absolute;
    top: 100%;
    /* left: calc(100% + 10px); */
    /* width: 5px;
    height: 5px;  */
  }
}